import { SignInButton } from "../components/buttons/SignInButton";

export function Login() {
  return (
    <div className="flex flex-col py-10 space-y-5">
      <div className="text-center">
        <p className="mb-5 text-2xl font-bold text-white">
          Welcome to Pelagia AS partner portal
        </p>
      </div>
      <div className="text-center">
        <p className="text-white text-l">
          In the portal you will find information regarding your cooperation
          activities with Pelagia. <br></br> Please sign in to get access to
          your portal.
        </p>
      </div>
      <div className="text-center">
        <p className="text-white text-l">
          In case of emergency assignments, related to silage pick up,
          <br /> please dial our department Hordafor directly on this emergency
          number: <br />
          <a className="underline" href="tel:+47 902 76 706">
            +47 902 76 706
          </a>
        </p>
      </div>
      <div className="text-center">
        <p className="text-white text-l">
          For all other inquiries contact your Pelagia contact person directly.
        </p>
      </div>
      <div className="flex w-full justify-center gap-4">
        <SignInButton />
        <a
          href={process.env.REACT_APP_ACCESS_PACKAGES_URL}
          className="px-4 py-3 text-white rounded bg-pelagia-dark hover:opacity-75"
          target="_blank"
          rel="noopener noreferrer"
        >
          New user
        </a>
      </div>
    </div>
  );
}
