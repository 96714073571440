import { useMsal } from "@azure/msal-react";
import React from "react";

export const SignInButton = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <button
      className="px-4 py-3 text-white rounded bg-pelagia-dark hover:opacity-75"
      onClick={initializeSignIn}
    >
      Log In
    </button>
  );
};
